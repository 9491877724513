var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"place-info-form",attrs:{"wrap":""}},[(_vm.label)?_c('v-flex',{attrs:{"xs12":""}},[_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.label)+" :")])]):_vm._e(),_c('v-flex',{staticStyle:{"padding":"5px 0"},attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('booking.address', _vm.locale),"hide-details":""},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleChange.apply(void 0, [ 'blur' ].concat( args )) }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-flex',{staticStyle:{"padding":"5px 5px 5px 0"},attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('booking.lat', _vm.locale),"hide-details":""},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleChange.apply(void 0, [ 'blur' ].concat( args )) }},model:{value:(_vm.lat),callback:function ($$v) {_vm.lat=$$v},expression:"lat"}})],1),_c('v-flex',{staticStyle:{"padding":"5px 0 5px 5px"},attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('booking.lon', _vm.locale),"hide-details":""},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleChange.apply(void 0, [ 'blur' ].concat( args )) }},model:{value:(_vm.lon),callback:function ($$v) {_vm.lon=$$v},expression:"lon"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }