<template>
  <v-layout wrap class="place-info-form">
    <v-flex xs12 v-if="label">
      <p class="label">{{label}} :</p>
    </v-flex>
    <v-flex xs12 style="padding: 5px 0;">
      <v-text-field
        outlined
        dense
        v-model="name"
        :label="$t('booking.address', locale)"
        @input="(...args) => { handleChange('blur', ...args) }"
        hide-details
      />
    </v-flex>
    <v-flex xs6 style="padding: 5px 5px 5px 0;">
      <v-text-field
        outlined
        dense
        v-model="lat"
        :label="$t('booking.lat', locale)"
        @input="(...args) => { handleChange('blur', ...args) }"
        hide-details
      />
    </v-flex>
    <v-flex xs6 style="padding: 5px 0 5px 5px;">
      <v-text-field
        outlined
        dense
        v-model="lon"
        :label="$t('booking.lon', locale)"
        @input="(...args) => { handleChange('blur', ...args) }"
        hide-details
      />
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'PlaceInfoForm',
  props: {
    locale: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    name: null,
    lat: null,
    lon: null,
  }),
  watch: {
    value () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.name = this.value.name
      if (!this.value || !this.value.coordinates) return
      const aux = this.value.coordinates.split(',')
      if (aux.length !== 2) return
      this.lat = aux[0]
      this.lon = aux[1]
    },
    handleChange () {
      const result = {}
      if (this.name && this.name.length > 0) result.name = this.name
      if (this.lat && this.lon && this.lat.length > 0 && this.lon.length > 0) result.coordinates = `${this.lat},${this.lon}`
      this.$emit('input', result)
    },
  }
}
</script>
<style scoped>
.place-info-form {
  background-color: #CCCCCC;
  border-radius: 10px;
  padding: 10px;
}
.place-info-form p {
  margin: 0px;
}
</style>

